export const workerRoutes = [
  {
    path: '/keepRecordList',
    name: '维稳记录',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/keepRecordList/index.vue'),
  },
  {
    path: '/safeStudyRecord',
    name: '安全培训记录',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/safeStudyRecord/index.vue'),
  },
  {
    path: '/wageRecord',
    name: '工资发放记录',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/wageRecord/index.vue'),
  },
  {
    path: '/workerList',
    name: '员工列表',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/workerList/index.vue'),
  },
  {
    path: '/inoutDoc',
    name: '进厂退厂合同管理',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/inoutDoc/index.vue'),
  },
  {
    path: '/inoutRecord',
    name: '进出场记录',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/inoutRecord/index.vue'),
  },
  {
    path: '/itemManage',
    name: '项目管理',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/worker/itemManage/index.vue'),
  },
];
