export const systemRoutes = [
  {
    path: '/dataDictionary',
    name: '数据字典',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/system/dataDictionary/index.vue'),
  },
  {
    path: '/auth',
    name: '权限控制',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/system/authControl/index.vue'),
  },
];

export default systemRoutes;
