import { MISSING_TYPE } from '@/model/comom';
import { watch, computed, ref } from 'vue';

function useModelValue<T>(value: () => T | undefined, onChange: (val?: T) => void, defaultValue?: T) {
  const _value = ref<T>();
  _value.value = value() ?? defaultValue;
  const sValue = computed({
    get() {
      return value() ?? _value.value;
    },
    set(val: T | undefined) {
      _value.value = val;
      onChange(val);
    },
  });
  watch(value, () => {
    _value.value = value();
  });
  return { value: sValue };
}

// 5位数字日期转为正常日期
export const formatExcelDate = (numb, format = "-") => {
  const time:MISSING_TYPE = new Date((numb - 1) * 24 * 3600000 + 1);
  time.setYear(time.getFullYear() - 70);
  const year = time.getFullYear() + "";
  const month = time.getMonth() + 1 ;
  const date = time.getDate() - 1 ;
  if (format && format.length === 1) {
    return year + format + month + format + date;
  }
  return (
    year + (month < 10 ? "0" + month : month+"") + (date < 10 ? "0" + date : date+"")
  );
};



export default useModelValue;
