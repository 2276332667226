<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="zhCN">
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <stepin-view
        system-name="项目全周期实名制管理"
        logo-src="../public/logo.png"
        :class="`${contentClass}`"
        :user="user"
        :navMode="navigation"
        :useTabs="useTabs"
        :themeList="themeList"
        :menuList="menuList"
        v-model:show-setting="showSetting"
        v-model:theme="theme"
        @themeSelect="configTheme"
      >
        <template #headerActions>
          <HeaderActions @showSetting="handleShowSet" />
        </template>
        <template #pageFooter>
          <PageFooter />
        </template>
        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
  </a-config-provider>
  <!-- <login-modal :unless="['/login']" /> -->
</template>

<script lang="ts" setup>
  import { reactive, ref } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAccountStore, useMenuStore, useSettingStore, storeToRefs, userStore } from '@/store';
  import { showSetting } from '@/store/user';
  // import { user, showSetting } from '@/store/user';
  import { PageFooter, HeaderActions } from '@/components/layout';
  import Setting from './components/setting';
  // import { LoginModal } from '@/pages/login';
  import { configTheme, themeList } from '@/theme';
  import { ThemeProvider } from 'stepin';
  import { computed } from 'vue';
  import { MenuConfig } from 'stepin/es/router-menu';
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import dayjs from 'dayjs';
  import 'dayjs/locale/zh-cn';
  dayjs.locale('zh-cn');
  const { user } = userStore();
  const { logout, profile } = useAccountStore();
  const handleShowSet = () => {
    showSetting.value = true;
  };
  //
  const menuList = ref<MenuConfig>([
    { title: '项目管理', path: '/itemManage', meta: { cacheable: false } },
    { title: '员工管理', path: '/workerList', meta: { cacheable: false } },
    { title: '维稳记录管理', path: '/keepRecordList', meta: { cacheable: false } },
    { title: '安全培训记录管理', path: '/safeStudyRecord', meta: { cacheable: false } },
    { title: '工资记录管理', path: '/wageRecord', meta: { cacheable: false } },
    { title: '进厂退厂合同管理', path: '/inoutDoc', meta: { cacheable: false } },
    { title: '进出场记录管理', path: '/inoutRecord', meta: { cacheable: false } },

    {
      title: '系统管理',
      path: '/system',
      meta: { icon: 'SettingOutlined' },
      children: [
        { title: '数据字典', path: '/dataDictionary', meta: { cacheable: false } },
        { title: '样列管理', path: '/auth', meta: { cacheable: false } },
      ],
    },
  ]);
  // 获取个人信息
  profile().then((response) => {
    // const { account } = response;
    user.name = response.userName;
  });

  const router = useRouter();

  useMenuStore().getMenuList();

  const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
  const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});

  function getPopupContainer() {
    return document.querySelector('.stepin-layout');
  }
</script>

<style lang="less">
  :deep(.ant-table-footer) {
    background-color: #fff;
    text-align: right;
  }
  :deep(.ant-table-tbody > tr > td) {
    padding: 4px;
  }
  .stepin-view {
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }
  .stepin-img-checkbox {
    @apply transition-transform;
    &:hover {
      @apply scale-105 ~"-translate-y-[2px]";
    }
    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
</style>
