import { defineStore } from 'pinia';
import avatar from '@/assets/manageLogo.pic.jpg';
import router from '@/router';
import { computed, reactive, ref } from 'vue';
import { useAccountStore } from '.';
export   const showSetting = ref(false);
export const userStore = defineStore('userStore', () => {
  const { logout } = useAccountStore();
  // 用户基础信息
  const userInfo = ref({});

  const userName = ref('admin');
  const user = reactive({
    name: userName.value,
    avatar: avatar,
    menuList: [
      { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
      { type: 'divider' },
      {
        title: '退出登录',
        key: 'logout',
        icon: 'LogoutOutlined',
        onClick: () => logout().then(() => router.push('/login')),
      },
    ],
  });

  const setUserName = (name) => {
    user.name = name;
  };
  return {
    userInfo: computed(() => userInfo),
    setUserName: setUserName,
    user,
  };
});

